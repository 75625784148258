@import url(https://fonts.googleapis.com/css?family=Saira:400,700);
body {
  margin: 0;
  padding: 0;
  background-color: #000;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@-webkit-keyframes colorfade {
  from {
    color: #fff;
  }
  to {
    color: #fefe00;
  }
}

@keyframes colorfade {
  from {
    color: #fff;
  }
  to {
    color: #fefe00;
  }
}

.timer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #F70D88;
}

.timer--counter {
  font-family: 'Saira', sans-serif;
  font-size: 27vmin;
  font-weight: 700;
}

.help {
  position: absolute;
  font-family: 'Saira', sans-serif;
  font-size: 1.5rem;
  top: 1vh;
  left: 1vw;
}

.white {
  color: #fff;
}

.ended-animation {
  -webkit-animation: colorfade cubic-bezier(0.68, -0.55, 0.265, 1.55) 1s infinite alternate;
          animation: colorfade cubic-bezier(0.68, -0.55, 0.265, 1.55) 1s infinite alternate;
}

.fill-white {
  fill: #fff;
}

.fill-grey {
  fill: #fff;
  fill-opacity: 0.3;
  mix-blend-mode: screen;
}

.icons {
  position: absolute;
  bottom: 6vh;
  display: flex;
}

.icon {
  height: 3.5em;
  width: auto;
}

.yellow {
  color: #fefe00;
}
